<script>
import ApiService from "@services/api.service";

export default {
  page: {
    title: "ScoreCard"
  },
  data() {
    return {
      table: {
        sortBy: "period",
        fields: [
          { key: "batchPeriod", class: "text-left" },
          { key: "uploadDate", class: "text-right" },
          { key: "actions", sortable: true, class: "text-right" }
        ],
        data: [],
        pageListOptions: [10, 20, 50, 100],
        paging: {
          pageNo: 1,
          pageSize: 12,
          recordCount: 1
        }
      },
      loader: {
        uploadButton: false,
        downloadButton: false,
        table: false
      },
      date: {
        year: 2020,
        month: "01"
      },
      monthOption: [
        { value: "01", description: "January" },
        { value: "02", description: "February" },
        { value: "03", description: "March" },
        { value: "04", description: "April" },
        { value: "05", description: "May" },
        { value: "06", description: "June" },
        { value: "07", description: "July" },
        { value: "08", description: "August" },
        { value: "09", description: "September" },
        { value: "10", description: "October" },
        { value: "11", description: "November" },
        { value: "12", description: "December" }
      ],
      file: null,
      error: null,
      successCount: null,
      showModal: true
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    yearOption: function () {
      let x = [];
      let y = 2020;
      for (y = 2020; y < 2051; y++) {
        x.push(y);
      }
      return x;
    }
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("scorecard")
        .then(resp => {
          this.table.data = resp;
          this.table.paging.recordCount = resp.length;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    uploadTemplate() {
      if (this.file) {
        this.loader.uploadButton = true;
        this.resetData();
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("batchPeriod", `${this.date.year}-${this.date.month}-01`);
        ApiService.upload("scorecard/upload", formData)
          .then(resp => {
            this.showToast("success", "Upload success");
            this.loadTable();
            this.$refs["uploadScore"].hide();
            this.successCount = resp;
          })
          .catch(err => {
            this.error = err;
          })
          .finally(() => {
            this.file = null;
            this.loader.uploadButton = false;
          });
      } else {
        this.showToast("error", "File must be uploaded first");
      }
    },
    downloadTemplate() {
      this.loader.downloadButton = true;
      ApiService.download("scorecard/template")
        .then(resp => {
          this.download(resp.data, "ScoreCardTemplate.xlsx");
        })
        .catch(err => this.showToast(err))
        .finally(() => {
          this.loader.downloadButton = false;
        });
    },
    resetData() {
      this.successCount = null;
      this.error = null;
    },
    cancelBatch(item) {
      this.showConfirm("Cancel will delete all leads on this batch.").then(resp => {
        if (resp) {
          this.loader.table = true;
          ApiService.delete("scorecard", { code: item.code })
            .then(() => {
              this.showToast("success", "Action Success");
              this.loadTable();
            })
            .catch(err => this.showToast("error", err))
            .finally(() => {
              this.loader.table = false;
            });
        }
      });
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div class="d-flex mb-2">
        <b-button class="mr-auto d-flex align-items-center" variant="outline-success" :disabled="loader.downloadButton" @click="downloadTemplate()">
          <b-spinner v-if="loader.downloadButton" variant="primary" label="Spinning" small class="mr-1" />
          Download Template
        </b-button>

        <b-button class="ml-auto d-flex align-items-center" variant="outline-info" @click.stop="$bvModal.show('uploadScore')">
          <b-spinner v-if="loader.uploadButton && file" variant="primary" label="Spinning" small />
          <span>Upload</span>
        </b-button>

        <v-select v-model="table.paging.pageSize" class="ml-2" placeholder="Record Per Page" :options="table.pageListOptions"></v-select>
      </div>

      <b-card v-if="successCount" title="You Have Uploaded Leads Successfully" border-variant="success" bg-variant="transparent">
        <hr />
        <h3>Record Count : {{ successCount }}</h3>
      </b-card>

      <b-card
        v-if="error"
        title="You have errors on your file."
        sub-title="Please check errors detail below."
        border-variant="danger"
        bg-variant="transparent"
      >
        <hr />
        <pre class="text-danger">{{ error }}</pre>
      </b-card>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :per-page="table.paging.pageSize"
        :current-page="table.paging.pageNo"
        :filter="table.filter"
        :busy="loader.table"
        no-provider-filtering
        empty-text="No data to"
        striped
        outlined
        hover
        show-empty
      >
        <template #cell(batchPeriod)="data">
          <div>
            {{ data.item.batchPeriod | date }}
          </div>
        </template>
        <template #cell(uploadDate)="data">
          <div>
            {{ data.item.uploadDate | dateTime }}
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="ml-auto" variant="outline-danger" @click="cancelBatch(data.item)"> Cancel Batch </b-btn>
          </div>
        </template>
      </b-table>

      <div class="d-flex mt-2">
        <span class="mr-auto"
          >Showing of <span class="font-weight-bold">{{ rowCount }}</span> of
          <span class="font-weight-bold">{{ table.paging.recordCount }}</span></span
        >
        <b-pagination
          v-model="table.paging.pageNo"
          class="ml-auto"
          :total-rows="table.paging.recordCount"
          :per-page="table.paging.pageSize"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
    <b-modal ref="uploadScore" id="uploadScore" title="Upload Score Card" centered no-close-on-backdrop hide-footer>
      <b-row>
        <b-col>
          <label> Month </label>
          <v-select
            v-model="date.month"
            label="description"
            placeholder="-- Please select date result --"
            :reduce="x => x.value"
            :options="monthOption"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label> Year </label>
          <v-select v-model="date.year" placeholder="-- Please select date result --" :options="yearOption" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="file"
            class="mt-2"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Upload Score Card Template..."
            accept=".xlsx"
          />
        </b-col>
      </b-row>
      <div class="d-flex mt-2">
        <b-btn class="mr-auto" variant="danger" :disabled="loader.uploadButton" @click="$bvModal.hide('uploadScore')"> Cancel </b-btn>
        <b-btn class="ml-2" variant="success" :disabled="loader.uploadButton" @click.stop="uploadTemplate"> Submit </b-btn>
      </div>
    </b-modal>
  </div>
</template>
